import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form']

  connect() {
    $(this.formTarget).on('turbo:submit-start', this.showLoader);
    $(this.formTarget).on('turbo:submit-end', this.hideLoader);
  }

  disconnect() {
    $(this.formTarget).off('turbo:submit-start', this.showLoader);
    $(this.formTarget).off('turbo:submit-end', this.hideLoader);
  }

  showLoader() {
    $('#loader').removeClass('hidden');
  }

  hideLoader() {
    $('#loader').addClass('hidden');
  }
}